$(function () {
  if (!$('.article-block').length) {
    return;
  }

  // Carousel settings
  var options = {
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: "unslick"
      }
    ]
  };

  // initialize carousel
  var $sl = $('.article-block__carousel').slick(options);

  function debounce(cb, delay = 250) {
    let timeout

    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        cb(...args)
      }, delay)
    }
  }

  // Check for window width changes and reinit when needed
  $(window).resize(debounce(function () {
    if ($(window).width() >= 992 && !$sl.hasClass('slick-initialized')) {
      $sl = $('.article-block__carousel').slick(options);
    }
  }));
})
