  $(document).ready(function () {
    $('.blog-lift-slider .container').slick({
      //dots: true,
      infinite: false,
      lazyLoad: 'ondemand',
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 1,
      prevArrow: $('.blogControls .arrow-prev'),
      nextArrow: $('.blogControls .arrow-next'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
    });
  });

