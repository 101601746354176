//Accordion
$(document).ready(function () {
  // Init the keyup event handler for newsletter ordering to hide the error and message elements when user is adding an email address.
  $("#newsletter-block").on("keyup", "#oaj-nl-email", function () {
    $(".error").hide("slow");
    $("#oaj-nl-msg").hide("slow");
  });
  // Init the on click event handler for newsletter ordering.
  $("#oaj-nl-button").on("click.newsletter", orderNewsletter);

  createAccordions();
  //createWall();

  // Calculate aspect ratios for visible embedded videos
  responsiveVideos();

  // Resize visible videos
  responsiveVideosResize();

  // Scroll smoothly anchor links
  var $root = $('html, body');
  $('.campaign-specified-lift-block a.button[href^="#"]').click(function () {
    var href = $.attr(this, 'href');

    if ($(href).length) {
      $root.animate({
        scrollTop: $(href).offset().top
      }, 500, function () {
        window.location.hash = href;
      });
    }

    return false;
  });

  $('.campaign-number-block a.button[href*="#"]').click(function () {
    var href = $.attr(this, 'href');

    if (href.indexOf(window.location.pathname) >= 0) {
      href = href.substring(href.indexOf("#"));

      if ($(href).length) {
        $root.animate({
          scrollTop: $(href).offset().top
        }, 500, function () {
          window.location.hash = href;
        });

        return false;
      }
    }

    // Fallback to link behavior
    return true;
  });
});

function createAccordions() {
  // Only run scripts if there's an accordion on page
  if ($('.accordion').length > 0) {
    $('.accordion .panel-heading a').click(function (e) {
      // Prevent default and propagate to panel-heading
      e.preventDefault();
    });

    // Panel heading acts as the toggle
    $('.accordion .panel-heading').click(function (e) {
      var parentPanel = $(this).parent();
      parentPanel.toggleClass("active");

      // Toggle active styles
      var isActive = parentPanel.hasClass("active");
      $(this).find('icon')
        .toggleClass("chevron-down", !isActive)
        .toggleClass("chevron-up", isActive);

      // Update hash according to anchor value
      var anchor = parentPanel.find('a.title');
      history.replaceState(undefined, undefined, anchor.attr('href'));

      // Calculate dimensions for embedded elements inside hidden panels
      if (isActive) {
        // Calculate aspect ratios for possible new embedded videos
        responsiveVideos();

        // Trigger window resize to calculate ratios
        $(window).resize();
      }
    });

    // Verify that an anchor with the hash exists on the page
    var hash = encodeURI(window.location.hash);
    if (hash.length > 0) {
      if ($(".accordion .panel-heading > a.title[href=\"" + hash + "\"]").length > 0) {
        $("a.title[href=\"" + hash + "\"]").click();
      }
    }
  }
}

//RESPONSIVE YOUTUBE VIDEOS
function responsiveVideos() {
  var $allVideos = $(".content iframe, .content object, .content embed");

  $($allVideos).each(function () {
    var $el = $(this);

    // Only calculate for visible elements with dimensions and which have not been calculated yet
    if ($el.is(':visible')
      && $el.width() > 0 && $el.height() > 0
      && hasAttr($el, "data-aspectRatio") === false) {

      // Check if the element has actual "width" and "height" attributes for aspect ratio, fallback to calculated:
      var elHeight = hasAttr($el, "height") ? $el.attr("height") : $el.height();
      var elWidth = hasAttr($el, "width") ? $el.attr("width") : $el.width();

      $($el)
        // jQuery .data does not work on object/embed elements
        .attr('data-aspectRatio', elHeight / elWidth)
        .removeAttr('height')
        .removeAttr('width');
    }
  });
}

function responsiveVideosResize() {
  var $allVideos = $(".content iframe, .content object, .content embed");

  $(window).resize(function () {
    $($allVideos).each(function () {
      var $el = $(this);

      // Only resize visible elements (hidden element dimensions are unaccessible)
      if ($el.is(":visible") && hasAttr($el, "data-aspectRatio") === true) {
        var newWidth = $el.parent().width();
        $el
          .width(newWidth)
          .height(newWidth * $el.attr('data-aspectRatio'));
      }
    });
  }).resize();
}

function hasAttr(element, attributeName) {
  var attr = $(element).attr(attributeName);
  return (typeof attr !== typeof undefined) && (attr !== false);
}

export function orderNewsletter(token) {
  var button = $("#oaj-nl-button");
  // Disable order button
  button.addClass("disabled");
  button.off(".newsletter").on("click.newsletter", function () { return false; });

  var url = $("#oaj-nl-pv-url");
  var listId = $("#oaj-nl-pv-id");
  if (url.length <= 0 || url.val().length <= 0 || listId.length <= 0 || listId.val().length <= 0) {
    $("#oaj-nl-i-error").show();
    return;
  }

  var email = $("#oaj-nl-email");
  if (email.length <= 0 || email.val().length <= 0) {
    $('#oaj-nl-error').show();
    enableNewsletterButton(button);
  }
  else {
    var post_data = {
      email: email.val(),
      join: listId.val()
    };

    // If token is available, append it to post data
    if (typeof token !== 'undefined' && token.length) {
      post_data["g-recaptcha-response"] = token;
    }

    $.ajax({
      url: url.val(),
      type: "POST",
      data: post_data
    })
      .done(function (data, textStatus, jqXHR) {
        if (jqXHR.status === 200) {
          if (data.success) {
            // Success
            $("#oaj-nl-msg").show();
          }
          else if (data.error_key === 'invalid-email' || data.error_key === 'email-cannot-be-null') {
            // Email invalid or empty
            $("#oaj-nl-error").show();
            enableNewsletterButton(button);
          }
          else {
            // Other error
            $("#oaj-nl-i-error").show();
            enableNewsletterButton(button);
          }
        }
        else {
          // Error
          $("#oaj-nl-i-error").show();
        }
      })
      .fail(function (data, textStatus, jqXHR) {
        // Error
        $("#oaj-nl-i-error").show();
      });
  }
}

function enableNewsletterButton(el) {
  el.removeClass("disabled");
  el.off(".newsletter").on("click.newsletter", orderNewsletter);
}

export function disableFormSubmit(e) {
  e.preventDefault();
  return false;
}

// Number scrolling
$(document).ready(function () {
  $(".h4-center-number span.number").each(function () {
    var $numberElement = $(this);
    var value = addSeparators($numberElement.text().trim());
    $numberElement.html(value);
  });

  $(".number-scrollable").each(function () {
    var $scrollElement = $(this);

    // Init starting value on page load
    $scrollElement.html($scrollElement.attr("data-min"));

    // Set waypoint for animation
    var waypoint = new Waypoint({
      element: $scrollElement,
      handler: function () {
        numScrolling($scrollElement);
        // Only run once
        this.destroy();
      },
      offset: 'bottom-in-view'
    });
  });
});

function numScrolling(element) {
  var $scrollElement = $(element);
  var minValue = parseInt($scrollElement.attr("data-min"));
  var maxValue = parseInt($scrollElement.attr("data-max"));
  var increment = parseInt($scrollElement.attr("data-increment"));
  var delay = (parseInt($scrollElement.attr("data-delay")) * 1000);

  $scrollElement.prop('ScrollCounter', minValue).animate({
    ScrollCounter: maxValue
  }, {
    duration: delay,
    easing: 'linear',
    step: function (now) {
      // Calculate increments as set on the parameters
      var valStep = (now + Math.abs((now % increment) - increment)).toFixed(0);

      // Increment calculation isn't exact, truncate at maximum value
      if (valStep > maxValue) {
        valStep = maxValue;
      }

      // Convert to string, add separators and append as HTML
      var valString = '' + valStep;
      var valHtml = addSeparators(valString);
      $scrollElement.html(valHtml);
    }
  });
}

function addSeparators(str) {
  // Add separators only to values above 9999
  if (str.length < 5) {
    return str;
  }

  // Add separators recursively
  var result = '';
  var separator = '<span class="separator"></span>';
  while (str.length > 0) {
    var part = str.substring(str.length - 3);
    result = (part.length == 3 ? separator : '') + part + result;
    str = str.substring(0, str.length - 3);
  }
  return result;
}

// Project news form functionality
$(document).ready(function () {

  if ($('#projectnews-form').length) {

    // TinyMCE initialization
    if ($('textarea#ProjectNewsPost_MainBody').length) {
      tinymce.init({
        selector: 'textarea#ProjectNewsPost_MainBody',
        height: 500,
        menubar: true,
        plugins: 'wordcount link',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        skin_url: '/build/tinymce/skins/ui/oxide',
        content_css: '/build/tinymce/skins/content/default/content.min.css',
        setup: function (editor) {
          //Restrict length of the post to 1000 word. Added event handler for submitting form, so that we can check form values in client side.
          var max = 1000;
          editor.on('submit', function (event) {
            var numChars = tinymce.activeEditor.plugins.wordcount.body.getWordCount();

            //Check that title, author name and mainbody have values, if not then prevent submit and show div that contains correct error message
            if ($('#ProjectNewsPost_Title').val().length === 0) {
              $('#missing-info-title').removeClass("missinginfos");
              event.preventDefault();
              return false;
            } else {
              $('#missing-info-title').addClass("missinginfos");
            }

            if ($('#ProjectNewsPost_AuthorName').val().length === 0) {
              $('#missing-info-authorname').removeClass("missinginfos");
              event.preventDefault();
              return false;
            } else {
              $('#missing-info-authorname').addClass("missinginfos");
            }

            if (numChars == 0) {
              $('#missing-info-mainbody').removeClass("missinginfos");
              event.preventDefault();
              return false;
            } else {
              $('#missing-info-mainbody').addClass("missinginfos");
            }

            //Check that mainbody isn't too long
            if (numChars > max) {
              $('#missing-info-mainbodytoolong').removeClass("missinginfos");
              event.preventDefault();
              return false;
            } else {
              $('#missing-info-mainbodytoolong').addClass("missinginfos");
            }
          });
        }

      });
    }

    // Form image selectors
    $('.image-row img').on('click', function (e) {
      var id = $(this).data('id');
      var input = $(this).siblings('input[value="' + id + '"]');
      var checked = $(input).is(':checked');

      // Remove all (previously) checked
      $('.image-row input').prop('checked', false);

      if (!checked) {
        $(input).prop('checked', true);
      }
    });

    $('#form-cancel').on('click', function (e) {
      $(".panel").removeClass('active');
    });
  }
});