$(function () {
  var speed = 3000;

  // Get the speed for changing the elements within carousel
  var customSpeed = $('#CurrentPage_HeroElementChangeSpeed').val();

  if (typeof customSpeed !== 'undefined' && customSpeed > 0) {
    speed = customSpeed;
  }

  $('.hero-block').each(function () {
    var $this = $(this);
    var sliderInitialized = false;

    // Set backgrounds
    $this.find('.hero-block__slide-content').each(function () {
      var backgroundClass = $(this).data('background');
      $(this).parent().addClass(backgroundClass);
    });

    function setHeight() {
      var height = 0;

      if (sliderInitialized) {
        $this.find('.hero-block__slide').css('height', '');

        $this.find('.slick-slide').each(function () {
          if (height < $(this).outerHeight()) {
            height = $(this).outerHeight();
          }
        });

        $this.find('.hero-block__slide').css('height', height);
      }
    }

    function debounce(cb, delay = 250) {
      let timeout

      return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          cb(...args)
        }, delay)
      }
    }

    $(window).resize(debounce(setHeight));

    $this.on('init', function () {
      sliderInitialized = true;
      setHeight();
    });

    $this.slick({
      autoplay: true,
      arrows: false,
      dots: false,
      fade: true,
      autoplaySpeed: speed
    });
  });
});
