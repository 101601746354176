$(function () {
  // Close search on click outside
  $(document).click(function (e) {
    if (!$(e.target).is(".header-stripe__search-toggler") && !$(e.target).is(".header-stripe__search-input")) {
      $('.header-stripe__search-toggler').removeClass("header-stripe__search-toggler--open");
    }
  });

  // Open search
  $(".header-stripe__search-toggler").on('click', function () {
    $(".header-stripe__search-toggler").addClass("header-stripe__search-toggler--open");
  });

  // Menu toggle
  $('.js-mobile-menu-toggler').click(function () {
    $('.header').toggleClass('header--menu-open');
  });
});
