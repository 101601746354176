$(function () {
  var mainMenuTimer;
  var isMobile = $(window).width() <= 991 ? true : false;

  $(".main-menu__submenu-toggler, .meganavigation__submenu-toggler, .meganavigation__list-link").on('click', function (event) {
    if (isMobile) {
      var $this = $(this);
      var $menuItem = $this.parent(".main-menu__item");

      if (!$menuItem.hasClass('main-menu__item')) {
        $menuItem = $this.parent(".meganavigation__list-item");
      }

      if (event.type != 'mouseover') {
        if ($menuItem.hasClass('main-menu__item')) {
          $menuItem.toggleClass("main-menu__item--uncollapsed");
        }
        else {
          $menuItem.toggleClass("meganavigation__list-item--uncollapsed");
        }
      }
    }
  });

  $(".main-menu__item")
    .mouseenter(function () {
      if (!isMobile) {
        var $el = $(this);

        clearTimeout(mainMenuTimer);

        if (!$el.hasClass("main-menu__item--uncollapsed")) {
          $(".main-menu__item").removeClass("main-menu__item--uncollapsed");
        }

        mainMenuTimer = setTimeout(function () {
          $el.addClass("main-menu__item--uncollapsed");
        }, 200);
      }
    })
    .mouseleave(function () {
      if (!isMobile) {
        var $el = $(this);

        clearTimeout(mainMenuTimer);

        mainMenuTimer = setTimeout(function () {
          $(".main-menu__item").removeClass("main-menu__item--uncollapsed");
          $el.parent('.main-menu__item').off('mouseenter mouseleave');
        }, 500);
      }
    });

  $(window).resize(function () {
    var newWidth = $(window).width() <= 991 ? true : false;

    if (isMobile != newWidth) {
      $('.main-menu__item').removeClass('main-menu__item--uncollapsed');
      $('.meganavigation__list-item').removeClass('meganavigation__list-item--uncollapsed');

      isMobile = newWidth;
    }
  });
});
